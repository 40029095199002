import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Colors from '../Colors';
import Certification from './Certification';

const Training: React.VoidFunctionComponent = () => {
  const { t } = useTranslation('academy');

  return (
    <>
      <section id="trainer-details">
        <div className={`${Colors.BLACK_ON_YELLOW}`}>
          <div className="container flex h-full max-w-224 flex-col justify-center px-4 py-8 sm:px-8">
            <h2 className="text-left text-4xl font-semibold">
              {t('training.details.trainer.title')}
            </h2>
            <div className="mt-6">{t('training.details.trainer.description')}</div>
          </div>
        </div>
      </section>
      <section id="certified-trainings">
        <div className={`${Colors.BLACK_ON_YELLOW}`}>
          <div className="container flex h-full max-w-224 flex-col justify-center px-4 pb-8 sm:px-8">
            <h2 className="text-left text-4xl font-semibold">
              {t('training.details.certification.title')}
            </h2>
            <div className="my-8 w-full lg:pr-12">
              <Certification />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Training;
