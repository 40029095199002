import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Colors from '../../components/Colors';
import { StaticImage } from 'gatsby-plugin-image';

type CertificationProps = {
  className?: string;
  textClassName?: string;
};

const Certification: React.VoidFunctionComponent<CertificationProps> = ({
  className,
  textClassName,
}) => {
  const { t } = useTranslation('academy');

  return (
    <a
      href="/academy/Certificat-QUALIOPI-REACTEEV.pdf"
      target="_blank"
      rel="noopener noreferrer"
      title={t('training.details.certification.download')}
      className="block"
    >
      <div className={`${Colors.BLACK_ON_WHITE} pb-4 ${className ?? 'w-full pt-2 sm:pt-4'}`}>
        <StaticImage
          src="../../images/academy/logo-qualiopi.png"
          className="h-auto w-full max-w-88"
          title={t('training.details.certification.qualiopi')}
          alt={t('training.details.certification.qualiopi')}
          placeholder="blurred"
        />
        <div className={textClassName ?? 'px-6 xs:px-8 sm:pl-9'}>
          <div>{t('training.details.certification.scope-title')}</div>
          <div className="mt-1 text-lg font-bold">
            {t('training.details.certification.scope-content')}
          </div>
        </div>
      </div>
    </a>
  );
};

export default Certification;
