import * as React from 'react';
import DiscoverDesign from './DiscoverDesign';

type DiscoverLinkProps = {
  href: string;
  text: string;
  pseudoClass: string;
  svgClass?: string;
};

const DiscoverLink: React.VoidFunctionComponent<DiscoverLinkProps> = ({
  href,
  text,
  pseudoClass,
  svgClass,
}) => {
  return (
    <DiscoverDesign
      component="a"
      text={text}
      pseudoClass={pseudoClass}
      svgClass={svgClass}
      props={{
        href,
        target: '_blank',
        rel: 'noopener noreferrer',
      }}
    />
  );
};

export default DiscoverLink;
