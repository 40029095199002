import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Introduction from '../../components/academy/Introduction';
import LinkedDocuments from '../../components/academy/LinkedDocuments';
import Training from '../../components/academy/Training';
import DefaultLayout from '../../components/layouts/default-layout';
import { VoidPageProps } from '../../utils/graphql';
import * as Colors from '../../components/Colors';

const AcademyIndex: React.VoidFunctionComponent<VoidPageProps> = ({ pageContext }) => {
  const { t } = useTranslation('academy');
  return (
    <DefaultLayout
      headTitle={t('head-title')}
      headDescription={t('head-description')}
      lang={pageContext.locale}
      theme={{ class: Colors.WHITE_ON_BLUE, pseudoClass: Colors.WHITE_ON_BLUE_PSEUDO_CLASS }}
      preserveNavbarSpace={false}
      showContactForm={true}
    >
      <Introduction linkTo="#trainer-details" />
      <div className="relative -z-20 flex h-screen w-0 snap-start"></div>
      <div className="relative z-10">
        <Training />
        <LinkedDocuments />
      </div>
    </DefaultLayout>
  );
};

export default AcademyIndex;
